import { CloseIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";
import ProfileMenu from "./ProfileMenu";

const Shadow = styled.div<{ isOpen: boolean }>`
  pointer-events: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition: all 0.5s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: rgba(0, 0, 0, 0.7);
    `}
`;

const ProfileDrawer: React.FC<{
  isOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}> = ({ isOpenState }) => {
  const [isOpen, setIsOpen] = isOpenState;
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  return (
    <div ref={ref}>
      <Shadow isOpen={isOpen} />
      <AnimatePresence>
        {isOpen && (
          <Box zIndex={1000} position="fixed" right={0} top="59px">
            <motion.div
              initial={{ x: "300px" }}
              animate={{ x: 0 }}
              transition={{ ease: "easeIn", duration: 0.2 }}
              exit={{ x: "300px" }}
            >
              <Box
                display="flex"
                backgroundColor="#FFF"
                height="calc(100vh - 60px)"
                width="300px"
                justifyContent="center"
                py={20}
                pos="relative"
              >
                <IconButton
                  onClick={() => setIsOpen(false)}
                  variant="outline"
                  pos="absolute"
                  top="1rem"
                  right="1rem"
                  icon={<CloseIcon />}
                  aria-label="close"
                />
                <ProfileMenu close={() => setIsOpen(false)} />
              </Box>
            </motion.div>
          </Box>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProfileDrawer;
