import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { RegisterCompanyMemberViewModel } from "services/new-member";
import { CompanyFormUiStore, NewMemberStore, NewMemberUiStore } from "stores";
import LocaleStore from "stores/domain/locale";
import CompanyForm from "./components/CompanyForm";

interface Props {
  companyFormUiStore?: CompanyFormUiStore;
  localeStore?: LocaleStore;
  newMemberStore?: NewMemberStore;
  memberUiStore?: NewMemberUiStore;
}

interface PropsWithRoute extends Props, RouteComponentProps<{}> {}
@inject("companyFormUiStore", "localeStore", "newMemberStore", "memberUiStore")
@observer
export default class RegisterCompanyMemberPage extends React.Component<
  PropsWithRoute,
  {}
> {
  @observable isSubmitting: boolean = false;

  constructor(props: Props) {
    super(props as any);
    makeObservable(this);
  }

  componentDidMount() {
    this.props.companyFormUiStore!.resetForm();
  }

  get localeStore() {
    return this.props.localeStore!;
  }

  get store() {
    return this.props.companyFormUiStore!;
  }
  get newMemberStore() {
    return this.props.newMemberStore!;
  }

  get companyRegistrationViewModel(): RegisterCompanyMemberViewModel {
    const {
      password,
      repeatedPassword,
      contactFirstName,
      contactLastName,
      orgNumber,
      orgName,
      phoneNumber,
    } = this.store;
    const { userId, resetToken } = this.newMemberStore;
    return {
      password: password.$,
      repeatedPassword: repeatedPassword.$,
      orgNumber: orgNumber.$,
      orgName: orgName.$,
      firstName: contactFirstName.$,
      lastName: contactLastName.$,
      phoneNumber: phoneNumber.$,
      language: this.localeStore.language,
      userId: userId,
      resetToken: resetToken,
    };
  }

  get memberUiStore() {
    return this.props.memberUiStore!;
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let validation = await this.store.form.validate();

    if (validation.hasError) {
      return;
    }
    const res = await this.newMemberStore.registerCompany(
      this.companyRegistrationViewModel
    );
    if (res != null && !res.succeeded) {
      this.memberUiStore.handleError(res);
    } else if (res != null) {
      this.props.history.push("/account/login");
    }
  };

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <CompanyForm
        hasFieldError={hasFieldError}
        formStore={this.store}
        onSubmit={this.onSubmit}
        isSubmitting={this.isSubmitting}
      />
    );
  }
}
