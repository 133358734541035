import {
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
} from "@chakra-ui/react";
import ProfileMenu from "./ProfileMenu";

const ProfilePopover: React.FC<{
  isOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}> = ({ isOpenState: [, setIsOpen] }) => {
  return (
    <>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <ProfileMenu close={() => setIsOpen(false)} />
        </PopoverBody>
      </PopoverContent>
    </>
  );
};

export default ProfilePopover;
